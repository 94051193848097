import { isValidEmail } from "@/helpers/customValidators";
import { FormBlock } from "../types";

const validEmails = ["greindel@infonaligy.com", "james@joyce.com"];

export const basicForm: FormBlock[] = [
  {
    element: "html",
    id: "sectionOne",
    tagName: "div",
    classList: ["mb-4"],
    children: [
      {
        element: "html",
        id: "headerOne",
        tagName: "h3",
        innerText: "Welcome To Form!",
        classList: ["font-bold", "text-lg"]
      }
    ]
  },
  {
    element: "html",
    id: "sectionTwo",
    tagName: "div",
    innerText: "",
    classList: ["mb-4"],
    children: [
      {
        element: "field",
        id: "fieldUsername",
        classList: [],
        properties: {
          key: "username",
          type: "text",
          label: "Enter username",
          placeholder: "",
          required: true,
          validations: [
            {
              method: "email",
              error: "invalid email"
            },
            {
              method: "custom",
              customValidator: (value: any) => {
                if (!value || !isValidEmail(value)) return true;
                return validEmails.includes(value);
              },
              error: "Email does not exist"
            }
          ]
        }
      },
      {
        element: "field",
        id: "fieldPassword",
        classList: [],
        properties: {
          key: "password",
          type: "password",
          label: "Password",
          placeholder: "",
          required: true,
          conditions: {
            and: [
              {
                operator: "equals",
                field: "username",
                value: "greindel@infonaligy.com"
              }
            ]
          }
        }
      }
    ]
  },
  {
    element: "html",
    id: "sectionThree",
    tagName: "div",
    innerText: "",
    classList: ["mb-4", "flex"],
    children: [
      {
        element: "field",
        id: "fieldSomeField",
        classList: ["w-1/2 mr-4"],
        properties: {
          key: "someField",
          type: "text",
          label: "Enter Name",
          placeholder: "",
          required: true
        }
      },
      {
        element: "field",
        id: "fieldSomeOtherField",
        classList: ["w-1/2"],
        properties: {
          key: "someOtherField",
          type: "text",
          label: "Enter Other Name",
          placeholder: "",
          required: true
        }
      }
    ]
  },
  {
    element: "html",
    id: "sectionFour",
    tagName: "div",
    innerText: "",
    classList: ["mb-4", "flex"],
    children: [
      {
        element: "field",
        id: "fieldFirstName",
        classList: ["w-1/3 mr-4"],
        properties: {
          key: "firstName",
          type: "text",
          label: "Enter First Name",
          placeholder: "",
          required: true
        }
      },
      {
        element: "field",
        id: "fieldMiddleName",
        classList: ["w-1/3 mr-4"],
        properties: {
          key: "middleName",
          type: "text",
          label: "Enter Middle Name",
          placeholder: "",
          required: true
        }
      },
      {
        element: "field",
        id: "fieldLastName",
        classList: ["w-1/3"],
        properties: {
          key: "lastName",
          type: "text",
          label: "Enter Last Name",
          placeholder: "",
          required: true
        }
      }
    ]
  }
];

export const yesNoSchema = [
  {
    element: "field",
    classList: ["mb-4"],
    properties: {
      key: "weather",
      required: true,
      type: "radio_button_group",
      preamble: "Is the weather cold?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false }
      ]
    }
  },
  {
    element: "field",
    classList: ["mb-4"],
    properties: {
      key: "group",
      type: "radio_button_group",
      preamble: "Which group do you belong to?",
      options: [
        { label: "Proletarians", value: "proles" },
        { label: "Party Member", value: "party member" },
        { label: "Inner Party Member", value: "inner party member" }
      ]
    }
  },
  {
    element: "field",
    classList: ["mb-4"],
    properties: {
      key: "philosophy",
      type: "radio_button_group",
      preamble: "Which of these philosophies do you like most?",
      options: [
        { label: "Ingsoc", value: "ingsoc" },
        { label: "Nazism", value: "nazism" },
        { label: "Fascism", value: "fascist" }
      ]
    }
  },
  {
    element: "html",
    id: "sectionFour",
    tagName: "div",
    innerText: "",
    classList: ["mb-4 mt-8", "flex"],
    children: [
      {
        element: "field",
        id: "gender",
        classList: ["w-1/2 mr-4"],
        properties: {
          key: "gender",
          type: "select",
          label: "Gender",
          options: [
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Transgender Male", value: "Transgender Male" },
            { label: "Transgender Female", value: "Transgender Female" }
          ],
          placeholder: "",
          required: true
        }
      },
      {
        element: "field",
        id: "fieldMiddleName",
        classList: ["w-1/2 mr-4"],
        properties: {
          key: "fein",
          type: "taxId",
          label: "FEIN",
          placeholder: "",
          required: true
        }
      }
    ]
  }
];

export const basicFormValues = {};
