
import { validateFields } from "@/helpers";
import { pick } from "lodash";
import Vue from "vue";

import { basicForm } from "./basicForm";

export default Vue.extend({
  name: "form-builder-example-login",
  components: {
    "form-builder": () => import("../FormBuilder.vue")
  },
  data() {
    return {
      schemas: basicForm,
      loginData: {
        username: "",
        password: "",
        someField: "",
        someOtherField: "",
        firstName: "",
        middleName: "",
        lastName: ""
      },
      validationData: {
        fieldsWithErrors: [],
        formIsValid: false,
        fieldErrors: {}
      }
    };
  },
  methods: {
    loginHandler() {
      console.log("loginHandler of login formBuilder called");
    },
    loginDataChangedHandler(event: { key: string; value: string }) {
      (this.loginData as any)[event.key] = event.value;
    },
    buttonClickHandler(button: any) {
      console.log("Btn", button, " clicked");
    }
  },
  computed: {
    showLoginButton(): boolean {
      return (
        validateFields(pick(this.$refs, "field_login_email")) &&
        this.loginData.password.length > 0
      );
    },
    buttons(): any[] {
      return [
        {
          show: this.showLoginButton,
          title: "Login",
          size: "medium",
          class: "w-full"
        }
      ];
    }
  }
});
